import React from 'react';
import styled from 'styled-components';
import { Button } from 'kinedu-react-components';
import { GoogleLogin } from 'react-google-login';
import GoogleLogo from '../images/google.svg';
import { OAUTH_CLIENT_ID, windowGlobal } from '../config';
import useAuth from '../hooks/useAuth';

const StyledGoogleButton = styled(Button).attrs(({ altVersion }) => ({
  color: altVersion ? '#4285F4' : 'white',
  flat: true,
  rounded: true,
  small: true,
  fill: true,
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 20px;

  color: ${({ altVersion }) => altVersion ? 'white' : '#757575'};
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;

  span {
    margin: auto;
  }

  ${({ disabled, altVersion }) => disabled && `
    opacity: 0.4;
    background-color: ${altVersion ? '#4285F4' : 'white'};
  `}
`;

const GLogo = styled.img.attrs(() => ({ src: GoogleLogo }))`
  width: 18px;
  ${({ altVersion }) => altVersion ? '' : 'border-right: 1px solid #ACC2D3;'};
  padding: ${({ altVersion }) => altVersion ? '6px' : '0 10px 0 0'};
  margin: 0 10px 0 0;
  border-radius: ${({ altVersion }) => altVersion ? '100px' : '0px'};
  background-color: ${({ altVersion }) => altVersion ? 'white' : 'transparent'};
`;

const GoogleButton = ({ children, altVersion }) => {
  const isGoogleAuthEnabled = windowGlobal.localStorage.getItem('GOOGLE_AUTH_ENABLED');
  const { onGoogleAuth } = useAuth();

  return (
    <GoogleLogin
      clientId={OAUTH_CLIENT_ID}
      render={({ onClick, disabled }) => (
        <StyledGoogleButton
          onClick={onClick}
          altVersion={altVersion}
          disabled={disabled || !isGoogleAuthEnabled}
        >
          <GLogo altVersion={altVersion} />
          <span>{children}</span>
        </StyledGoogleButton>
      )}
      onSuccess={({ accessToken, profileObj }) => {
        windowGlobal.localStorage.setItem('GOOGLE_AUTH_ENABLED', 'true');
        onGoogleAuth(accessToken, profileObj);
      }}
      onFailure={(res) => {
        console.error('%c🐛 FAIL: res', 'background: #332167; color: #b3d1f6; font-size: 11px', res);
        windowGlobal.localStorage.setItem('GOOGLE_AUTH_ENABLED', '');
      }}
    />
  );
};

export default GoogleButton;
