import React from "react"
import styled from 'styled-components';

const StyledDivider = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0px;
`;

const Line = styled.span`
  width: 134px;
  height: 1px;
  background-color: #E9E9E9;
  margin: auto 0;
`;

const Txt = styled.span`
  margin: 0 20px;
  color: #333;
`;

const Divider = ({ text = 'OR' }) => {

  return (
    <StyledDivider>
      <Line />
      <Txt>{text}</Txt>
      <Line />
    </StyledDivider>
  );
}

export default Divider;
